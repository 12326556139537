.terminal-container {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  cursor: text;
  background-color: #171717;
}

.terminal-content {
  padding: 20px;
  font-size: 15px;
  line-height: 20px;
  white-space: pre-wrap;
  color: #aaa;
  font-family: monospace;
  display: flex;
  flex-direction: column;
}

.terminal-banner {
  color: teal;
  text-shadow: 0 0 5px teal;
  line-height: normal;
  font-weight: bold;
  font-size: calc(1vw + 7px);
  margin-bottom: 20px;
}
@media only screen and (max-width: 400px) {
  .terminal-banner {
    font-size: 8px;
  }
}

@media only screen and (max-width: 300px) {
  .terminal-banner {
    font-size: 6px;
  }
}

.terminal-welcome-message {
  margin-bottom: 20px;
}

.terminal-command-output {
  padding: 10px;
  max-width: 800px;
}

.terminal-glow,
.terminal-command-output dt {
  color: #eeeeee;
  text-shadow: 0 0 4px #eeeeee;
}

.terminal-command-output dd {
  margin-inline-start: 20px;
}

.terminal-command-output dd:not(:last-child) {
  margin-block-end: 0.3em;
}

.terminal-command-output dd::before {
  content: "- ";
}

.terminal-command-output ul {
  margin-top: 0;
}

.terminal-command-record {
  scroll-margin: 15px;
}

.terminal-input-area {
  display: inline-flex;
  width: 100%;
  align-items: center;
}

.terminal-prompt {
  margin-right: 5px;
}

.terminal-input {
  font-family: inherit;
  font-size: inherit;
  font-size: inherit;
  color: rgb(240, 191, 129);
  background: transparent;
  border: 0px;
  outline: none; /* no highlight on focus */
  width: 100%;
}

.terminal-error-group {
  display: flex;
  flex-direction: column;
}

.terminal-error {
  color: red;
}

.terminal-glow {
  color: #eeeeee;
  text-shadow: 0 0 4px #eeeeee;
}

a {
  color: #dea5f5;
}
a:hover {
  color: black;
  background-color: #c4a5f5;
  text-decoration: none;
}